import React from 'react';
import PageLayout from '@global/page-layout';
import { useStaticQuery, graphql } from 'gatsby';
import { TWButton } from '@styled/components';
import '../assets/styles/tailwind.css';
import { popup } from '@src/global/utils/typeformPopup';

const PricingPage = () => {
  const data = useStaticQuery(graphql`
    query allPricingSectionJson {
      pricingSectionJson {
        title
        subTitle
        pricing {
          title
          cost
          description
          features
          cta
          link
        }
      }
    }
  `);

  const { title, subTitle, pricing } = data.pricingSectionJson;
  return (
    <PageLayout title="Pricing">
      <div className="bg-gray-200 pt-6">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 ">
                {title}
              </h2>
              <p className="mt-4 text-xl leading-7 text-gray-600">{subTitle}</p>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-12 bg-gray-100 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 h-3/4 bg-gray-100"></div>
            <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 pt-6">
              <div className="max-w-md mx-auto lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5">
                {pricing.map((price, key) => (
                  <div key={key} className="pt-4 sm:pt-8 md:pt-8 ">
                    <div className="rounded-lg shadow-lg overflow-hidden">
                      <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                        <div>
                          <h3
                            className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-green-100 text-green-600"
                            id="tier-standard"
                          >
                            {price.title}
                          </h3>
                        </div>
                        <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                          {price.cost}
                          {price.title === 'Standard' ? (
                            <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                              /mo
                            </span>
                          ) : null}
                        </div>
                        <p className="mt-5 text-lg leading-7 text-gray-500">
                          {price.description}
                        </p>
                      </div>
                      <div className="px-6 pt-6 pb-8 bg-gray-100 sm:p-10 sm:pt-6">
                        <ul>
                          {price.features.map((feature, index) => (
                            <li
                              key={index}
                              className={
                                index === 0
                                  ? 'flex items-start'
                                  : 'mt-4 flex items-start'
                              }
                            >
                              <div className="flex-shrink-0">
                                {/* M6 18L18 6M6 6l12 12 */}
                                <svg
                                  className="h-6 w-6 text-primaryColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p className="ml-3 text-base leading-6 text-gray-700">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-6 rounded-md shadow">
                          <TWButton
                            className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primaryColor hover:bg-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                            title={price.cta}
                            onClick={() => popup()}
                          />
                        </div>
                        <p
                          className={
                            key === 0
                              ? 'text-xs mt-4 text-gray-600'
                              : 'text-xs mt-4 text-gray-100'
                          }
                        >
                          *All fees are billed annually
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PricingPage;
